.light-theme {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #222;
    --color-border: #ced4da;
    --color-text: #333;
    --color-background-alert: rgba(255,255,255, 0.9);
    --color-text-alert: #333;
    --form-element-background: #181818;
    --form-element-background-disabled: #333;
    --form-element-text-color: #fff;
    --form-element-text-color-2: #fff;
    --form-element-text-color-disabled: #a5a5a5;
    --form-element-border-radius: 5px;
    --form-element-border: #181818;
    --form-element-focus-shadow: rgb(255 255 255 / 5%);
    --form-element-dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    --color-ball-reserve-background: #f44336;
    --color-ball-reserve-text: #fff;
    --color-card-background: #fff;
    --color-card-text: #333;
    --color-card-border-radius: 10px;

    --color-table-header-background: #333;
    --color-table-header-text: #fff;
    --color-table-header-border: #333;
    --color-table-row-border: #d3d3d3;
    --color-table-border-radius: 8px;
    --color-table-row-odd-background: #f1f1f1;
    --color-table-row-hover-background: #e3e3e3;
    --color-table-pagination-button-background: #fff;
    --color-table-pagination-button-text: #333;
    --color-table-pagination-button-border: #333;
    --color-table-pagination-navigate-background: #333;
    --color-table-pagination-navigate-text: #fff;
    --color-table-pagination-navigate-border: #333;
    --color-table-pagination-button-disabled-background: #ccc;
    --color-table-pagination-button-disabled-text: #919191;
    --color-table-pagination-button-disabled-border: #ccc;

    --color-popup-overlay-background: rgba(0, 0, 0, .6);
    --color-popup-background: #333;
    --color-popup-border: #181818;
    --color-popup-text: #fff;

    --color-overview-table-value-background:  #919191;
    --color-overview-table-title-background: #e1e1e1;
    --color-overview-table-sign-background: #1c2b50;

    --color-header-background: #070505;
    --color-subheader-background: #ffffff;
    --color-header-text: #fff;
    --color-slider-background: #f1cc01;
    --color-secondary-on: #fff;
    --color-secondary-on2: #000;
    --text-color: #000;
    --text-color-on-primary: #333;
    --color-primary: #4fbbf7;
    --color-secondary: #181818;
    --color-board-number: #1dee19;
    --color-gray: rgba(0,0,0, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;

    --dice-color: #474747;
    --dice-color-hover: #2dca21;

    --song-color-background: #181818;
}


.admin-theme {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background-alert: rgb(84 84 84 / 90%);
    --color-text-alert: #fff;
    --color-background: #0e131e;
    --color-border: #383838;
    --color-text: #fff;
    --color-sidebar-background: #141d34;
    --color-sidebar-border: #141d34;
    --color-sidebar-text: #fff;

    --color-headerbar-background: #172342;
    --color-headerbar-border: #172342;
    --color-headerbar-text: #fff;

    --color-card-background: #141d34;
    --color-card-text: #fff;
    --color-card-border-radius: 10px;

    --color-overview-table-value-background:  #172342;
    --color-overview-table-title-background: #141c2e;
    --color-overview-table-sign-background: #1c2b50;

    --color-table-header-background: #172342;
    --color-table-header-text: #fff;
    --color-table-header-border: #172342;
    --color-table-row-border: #172342;
    --color-table-border-radius: 8px;
    --color-table-row-odd-background: #070505;
    --color-table-row-hover-background: #141d34;
    --color-table-pagination-button-background: #172342;
    --color-table-pagination-button-text: #fff;
    --color-table-pagination-button-border: #172342;
    --color-table-pagination-navigate-background: #141d34;
    --color-table-pagination-navigate-text: #fff;
    --color-table-pagination-navigate-border: #141d34;
    --color-table-pagination-button-disabled-background: #121b32;
    --color-table-pagination-button-disabled-text: #545454;
    --color-table-pagination-button-disabled-border: #121b32;

    --color-popup-overlay-background: rgba(0, 0, 0, .6);
    --color-popup-background: #172342;
    --color-popup-border: #070505;
    --color-popup-text: #fff;

    --form-element-background: #0e131e;
    --form-element-background-disabled: #1e1e1e;
    --form-element-text-color: #fff;
    --form-element-text-color-2: #f1f1f1;
    --form-element-text-color-disabled: #7a7a7a;
    --form-element-border-radius: 0px;
    --form-element-border: #000;
    --form-element-focus-shadow: rgb(241 204 1 / 25%);
    --form-element-dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    --color-ball-reserve-background: #f44336;
    --color-ball-reserve-text: #fff;

    --color-header-background: #070505;
    --color-subheader-background: #ffffff;
    --color-header-text: #fff;
    --color-slider-background: #f1cc01;
    --color-secondary-on: #fff;
    --color-secondary-on2: #000;
    --text-color: #fff;
    --text-color-on-primary: #333;
    --color-primary: #3091c7;
    --color-secondary: #1e6791;
    --color-board-number: #1dee19;
    --color-gray: rgba(0,0,0, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;

    --song-color-background: #1e2b46;
}

.btn-vote {
    --bs-btn-color: #fff;
    --bs-btn-bg: #000000;
    --bs-btn-border-color: #181818;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4fbbf7;
    --bs-btn-hover-border-color: #4fbbf7;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3091c7;
    --bs-btn-active-border-color: #3091c7;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #818181;
    --bs-btn-disabled-border-color: #818181;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3091c7;
    --bs-btn-border-color: #3091c7;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #181818;
    --bs-btn-hover-border-color: #181818;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #181818;
    --bs-btn-active-border-color: #181818;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #818181;
    --bs-btn-disabled-border-color: #818181;
}