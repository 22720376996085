body,
html{
    font-family: "Poppins", "IranSANS", sans-serif;
    background-color: var(--color-background);
    color: var(--text-color);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;

}
a{
    text-decoration: none;
    color: var(--color-primary);
}
a:hover{
    color: var(--color-secondary-on);
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
}

.Link{
    text-decoration: none;
    color: #d50f0a;
}
.Link:hover{
    color: #fff;
}
.LoadingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    height: calc(100vh - 100px);

}