.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.black-bg{
    background: #000;
}


.show-1 {
    transform: rotateX(720deg) rotateZ(-720deg); }

.show-6 {
    transform: rotateY(-450deg) rotateZ(-1440deg); }

.show-3 {
    transform: rotateY(-270deg) rotateZ(-1440deg); }

.show-4 {
    transform: rotateY(810deg) rotateZ(630deg); }

.show-5 {
    transform: rotateX(-630deg) rotateZ(-1080deg); }

.show-2 {
    transform: rotateY(540deg) rotateZ(-720deg); }

.two-1, .three-1, .four-1, .five-1, .six-1 {
    top: calc(20% + 3px);
    left: calc(20% + 3px);
}

.four-3, .five-3, .six-4 {
    top: calc(20% + 3px);
    left: calc(80% - 3px);
}

.one-1, .three-2, .five-5 {
    top: 50%;
    left: 50%;
}

.four-2, .five-2, .six-3 {
    top: calc(80% - 3px);
    left: calc(20% + 3px);
}

.two-2, .three-3, .four-4, .five-4, .six-6 {
    top: calc(80% - 3px);
    left: calc(80% - 3px); }

.six-2 {
    top: 50%;
    left: calc(20% + 3px);
}

.six-5 {
    top: 50%;
    left: calc(80% - 3px);
}

.chip-move{
    position: absolute;
    transition: all .2s ease-in-out !important;
    background: transparent !important;
    z-index: 99999;
    width: 32px !important;
    height: 32px !important;
}
.sort-list-item{
    list-style: none;
    cursor: grabbing;
    z-index: 999999999;
}